import ApiService, { HEADERS } from './Api';
import { apiPath, apiPathV2, authHeaders } from 'AppConstants';

const BASE_URL = apiPath;
const BASE_URL_V2 = apiPathV2;
let headers = {
    ...HEADERS,
    ...authHeaders,
    'KS-APP': 'KeepSpace Client-App v3',
    'API-VERSION': '1.0'

};
let client = new ApiService({ baseURL: BASE_URL, headers, withCredential: true })
let client_V2 = new ApiService({ baseURL: BASE_URL_V2, headers, withCredential: true })
const PAGE_LIMIT = 20;
const ARGS = `?per_page=${PAGE_LIMIT}`

const keepspaceApi = {}


// Internal Helpers
const formatBookingInbound = booking => ({
    // Map react variable names to rails variable names
    ...booking,

    // contact_name: booking,
    // contact_phone: booking.phone,
    // address: booking.address,
})

const formatBookingOutbound = booking => ({
    // Map react variable names to rails variable names
    ...booking,
})

/**
 * Public Methods for the KeepSpace API
 *
 * Example API Server can be found at [production-api.keepspace.com.au]
 * Authentication is based on the JWT standard, but sent as sepereate headers
 * More can be found in the redux-token-auth documentation.
*/

// Customers
keepspaceApi.getCustomers = () => client.get(`customers/`)
// Comments
keepspaceApi.getPublicComments = (args) => client.get(`public_comments?${args ? args : 'per_page=25&page=1'}`)
keepspaceApi.getPublicCommentById = id => client.get(`public_comments/${id}`)
keepspaceApi.createPublicComment = comment => client.post(`public_comments`, comment)

// Orders
keepspaceApi.getOrders = (args) => client_V2.get(`bookings?${args ? args : 'per_page=9999&page=1'}`)
keepspaceApi.getNewestOrders = () => client.get(`bookings${ARGS}`)
keepspaceApi.getOrderById = id => client.get(`bookings/${id}`)
keepspaceApi.createInbound = (booking, items) => client.post(`bookings/pick-up`, { booking: formatBookingInbound(booking), items })
keepspaceApi.createOutbound = (booking, items) => client.post(`bookings/item-delivery`, { booking: formatBookingOutbound(booking), items })
keepspaceApi.editInbound = (booking, items) => client.patch(`bookings/${booking.id}/pick-up`, { booking: formatBookingInbound(booking), items })
keepspaceApi.editOutbound = (booking, items) => client.patch(`bookings/${booking.id}/item-delivery`, { booking: formatBookingOutbound(booking), items })
keepspaceApi.getShippingCarriers = () => client.get(`shipping_carriers`)
keepspaceApi.getInboundReport = (bookingId) => client_V2.get(`bookings/${bookingId}/shipment_report`)
keepspaceApi.getInboundLabel = (bookingId) => client_V2.downloadFile(`bookings/${bookingId}/print_shipment_label`)

// Space, Items
keepspaceApi.getItems = (args) => client_V2.get(`items?${args ? args : 'per_page=9999&page=1'}`)
keepspaceApi.getTotalUsageVolume = () => client.get(`item_stocks/total_volume`)
keepspaceApi.archiveItem = id => client.post(`items/${id}/archive`)

// Space, Bundles
keepspaceApi.getBundles = () => client.get(`bundles`)

// Statistics
keepspaceApi.getMostActiveCountries = () => client.get(`most_active_countries`)
keepspaceApi.getSKUPerOrder = () => client.get(`sku_per_order`)
keepspaceApi.getOrderCount = () => client.get(`bookings_count`)
keepspaceApi.getTotalRevenue = () => client.get(`total_revenue`)
keepspaceApi.getMostActiveShopfronts = () => client.get(`most_active_shopfronts`)

// Suppliers
keepspaceApi.getSuppliers = () => client.get(`suppliers`)

// Organisations
keepspaceApi.getOrganisations = () => client.get(`organisations`)
keepspaceApi.updateOrganisation = (id, organisation) => client.put(`organisations/${id}`,  organisation)
keepspaceApi.createOrganisation = newOrganisation => client.post(`organisations`, newOrganisation)
// keepspaceApi.deleteOrganisation = id => client.delete(`organisations/${id}`)

// OrganisationUsers
keepspaceApi.getOrganisationUsers = () => client.get(`organisation_users`)
keepspaceApi.updateOrganisationUser = organisation_user => client.put(`organisation_users/${organisation_user.id}`, organisation_user)
keepspaceApi.createOrganisationUser = organisation_user => client.post(`auth/invitation`, organisation_user)
keepspaceApi.deleteOrganisationUser = id => client.delete(`organisation_users/${id}`)
keepspaceApi.acceptExsistingUserInvitation = organisation_user => client.post(`organisation_users/accept_invitation`, organisation_user)
keepspaceApi.resendInvitation = id => client.post(`organisation_users/resend_invitation`, {id: id})

// Meta
keepspaceApi.getMetaServerHash = () => client.get(`meta/git_hash`)
keepspaceApi.getFeatureFlags = () => client.get(`user_features/`)
keepspaceApi.getNotifications = () => client.get(`notifications`)

// User invitations
keepspaceApi.acceptInvitation = body => client.put(`auth/invitation`, body)

// User
keepspaceApi.updateUser = (id, body) => client.put(`users/${id}`, body)

/**
 * Recreates the axios client instance using the current access tokens.
 *
 * This is called directly after the login action, as the instance is likely
 * to have nil credentials when the page first loads.
 */
keepspaceApi.refreshAuthToken = () => {
    let authHeaders = {
        'access-token': window.localStorage.getItem('access-token'),
        'token-type': window.localStorage.getItem('token-type'),
        'client': window.localStorage.getItem('client'),
        'expiry': window.localStorage.getItem('expiry'),
        'uid': window.localStorage.getItem('uid'),
        'organisation-uid': window.localStorage.getItem('organisation-uid'),
    }
    headers = { ...HEADERS, ...authHeaders };
    client = new ApiService({ baseURL: BASE_URL, headers })
    client_V2 = new ApiService({ baseURL: BASE_URL_V2, headers })
    return true;
}

window.keepspaceApi = keepspaceApi

export default keepspaceApi;
